import React, {Suspense}  from 'react';
import Axios              from 'axios'
import {
  BrowserRouter as Router,
  Switch, Route }         from "react-router-dom";
import { appendMetaTag,
        hostUrl,
        cmsHost,
        appendLinkTag, 
        appendScript}   from './assets';

import Body               from './components/Body'
import Config             from './config/site.json'

import './App.scss';

const Home      = React.lazy(() => import('./components/Home'))
const Header    = React.lazy(() => import('./components/Header'))
const Footer    = React.lazy(() => import('./components/Footer'))
const Details   = React.lazy(() => import('./components/Details'))
const QuemSomos = React.lazy(() => import('./components/QuemSomos'))
const Adm       = React.lazy(() => import('./components/adm'))

const waiting = (<div
                    style={{width           :"100%",
                            height          :"100vh",
                            backgroundColor :"#F8F8F8",
                            alignItems      :"center",
                            justifyContent  :"center",
                            display         :"flex"}}
                  >
                    <div className="spinner-border"></div>
                  </div>)

Axios.defaults.withCredentials = true

export class App extends React.Component {
  state = {
    Config  : {},
    stock   : [],
    site    : true
  }

  getUrlStock = (url) => {
    const getStock = async () => {
      const {data} = await Axios.get(`${url}`);
              
      this.setState({stock:data})
    }
    getStock()
  }

  injectScript = (scripts, inject) => {
    if(scripts && scripts.length){
      const script = scripts.pop()
      appendScript(script.src,()=>{ this.injectScript(scripts, inject)})
    }else if(inject && inject.trim().length){
      eval(inject)
    }
  }
  componentDidMount(){
    const site = window.location.pathname.toLowerCase().indexOf("/adm")!==0,
      url =  hostUrl,
      start = async () => {
        const resp  = await Axios.post(`${cmsHost}/config/site/token/`, {url})
        if(resp.data.res==="Ok"){
          const {data} = await Axios.post(`${cmsHost}/config/site/props/` + resp.data.site._id, {url}),
            Config = data.site;

          if(Config){
            const {metas, links, scripts, inject, title, _id} = Config

            if(title) document.title = title

            if(metas) metas.forEach(m => appendMetaTag(m.name, m.content) ) 
            
            if(links) links.forEach( m => appendLinkTag(m.rel, `${cmsHost}/config/site/assets/${_id}/img/${m.href}`) )
            
            if(site)
              setTimeout(()=>{
                if(scripts && scripts.length){
                  this.injectScript(scripts.reverse(), inject)
                }else if(inject && inject.trim().length){
                  eval(inject)
                }
              }, 3000)
            // appendScript("https://az-infobots.take.net/SantanderScripts/lib/santander-partner-blipchat.min.js",()=>{
            //   eval("new PartnerBlipChat({ companyName: 'FernandoVeículo', tab: '433178', userDocument: '', uniqueId: '', blipChatAppKey: 'ZmluY2hhdGZlcm5hbmRvdmVpY3VsbzoxZTQ3MTBhZS1hNWZlLTQ5NDgtYTAyOS05YzE2NjFhNTkyOWE='}).start();")
            // })

            site && this.getUrlStock(Config.ws.stock) 
            window.location.pathname==="/adm" && this.getUrlStock(Config.ws.stock)
            window.location.pathname==="/adm/painel-inicial" && this.getUrlStock(Config.ws.stock)
          }

          this.setState({Config, site})
        }else this.setState({site})
      }
    start()
  }
  componentDidMountSTATIC(){
    const site = window.location.pathname.toLowerCase().indexOf("/adm")!==0
    if(Config){
      site && this.getUrlStock(Config.ws.stock)
      const {metas, links} = Config
      this.setState({Config, site})
      document.title= Config.title
      if(metas){
        metas.every(m => {
          let meta    = document.createElement('meta')
          meta.name   = m.name
          meta.content= m.content
          document.querySelector('head').appendChild(meta)
          return true
        })
      }
      if(links){
        links.every(m => {
          let link    = document.createElement('link')
          link.rel    = m.rel
          link.href   = m.href
          document.querySelector('head').appendChild(link)
          return true
        })
      }
    }
  }
  
  componentDidMountBLOQ(){
    const start = async () => {
      const {data}  = await Axios.get(`${cmsHost}/site/config/pgprime`),
            Config  = data;

      this.setState({Config})
      if(Config){
        const {metas} = Config
        document.title= Config.title
        if(metas){
          metas.every(m => {
            let meta    = document.createElement('meta')
            meta.name   = m.name
            meta.content= m.content
            document.querySelector('head').appendChild(meta)
            return true
          })
        }
      }
    }
    start()
  }
  
  WaitingComponent(Component, data) {
    return props => (
      <Suspense fallback={waiting}>
        <Component {...props} data={data}/>
      </Suspense>
    );
  }

  render(){
    const {Config, stock, site} = this.state 

    if(!Config.title && site)
      return waiting
      
    if(site && stock.length===0)
      return waiting
      
    return (
      <Router>
        <h1 className="d-none">{Config.title}</h1>
        <Suspense fallback={<></>}>
          <Header
            data={Config && Config.header ? {...Config.header, social:Config.social, shop:Config.shop, token:Config._id, site} : null }/>
        </Suspense>

        <Body>
          <Switch>
            <Route exact path="/comprar/:marca/:modelo/:versao/:ano/:id" component={this.WaitingComponent(Details, {Config, stock})}/>
            <Route exact path="/quem-somos" component={this.WaitingComponent(QuemSomos, {Config, stock})}/>
            <Route exact path="/adm/:modulo" component={this.WaitingComponent(Adm, {Config, stock})}/>
            <Route exact path="/adm" component={this.WaitingComponent(Adm, {Config, stock})}/>
            <Route path="/" component={this.WaitingComponent(Home, {Config, stock})}/>
          </Switch>
        </Body>
        
        <Suspense fallback={<></>}>
          <Footer data={Config && Config.footer ? {...Config.footer, social:Config.social, shop: Config.shop, token:Config._id, site} : null }/>
        </Suspense>
      </Router>
      
    );
  }
}

export default App;
/*
            <Route exact path="/comprar/:marca/:modelo/:versao/:ano/:id" render={(props) => <Details {...props} data={{Config, stock}} />}/>
            <Route exact path="/" render={(props) => <Home {...props} data={{Config, stock}} />}/>

            <Route path="/comprar/:marca/:modelo/:versao/:ano/:id">
              <Suspense fallback={<div className="spinner-border"></div>}>
                <Details data={Config ? Config : null } {...props}/>
              </Suspense>
            </Route>
*/
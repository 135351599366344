import React, { Component } from 'react'

export default class Body extends Component {
    render() {
        return (
            <div style={{minHeight:'100%'}}>
                {this.props.children}
            </div>
        )
    }
}
